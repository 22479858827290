





















































































































































































































































































































































































































































































































































































































































































































































































/deep/.row {
  margin-right: 0px;
}
@import "./css/boot.css";
@import "./css/bootsnav.css";
.website-logo {
  margin-right: 3rem;
  .logo {
    width: 60px;
    height: 60px;
    display: inline-block;
    margin: auto 20px auto 0;
  }
  .website-name {
    font-size: 2.4rem;
    display: flex;
    align-items: center;
  }
}
.navbar-brand {
  padding: 29px 15px;
  height: auto;
}
nav.navbar.bootsnav {
  border: none;
}
.navbar-nav {
  float: left;
}
nav.navbar.bootsnav ul.nav > li > a {
  color: #474747;
  text-transform: uppercase;
  padding: 3rem;
  font-size: 17px;
}
nav.navbar.bootsnav ul.nav > li:hover {
  background: #f4f4f4;
}
.nav > li:after {
  content: "";
  width: 0;
  height: 5px;
  background: #c9adab;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.5s ease 0s;
}
.nav > li:hover:after {
  width: 100%;
}
nav.navbar.bootsnav ul.nav > li.dropdown > a.dropdown-toggle:after {
  content: "+";
  font-family: "FontAwesome";
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  top: 35%;
  right: 8%;
  transition: all 0.4s ease 0s;
}
nav.navbar.bootsnav ul.nav > li.dropdown.on > a.dropdown-toggle:after {
  content: "›";
  transform: rotate(90deg);
}
.dropdown-menu.multi-dropdown {
  position: absolute;
  left: -100% !important;
}
nav.navbar.bootsnav li.dropdown ul.dropdown-menu {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border: none;
}
@media only screen and (min-width: 768px) {
  .navbar-header .logo {
    float: right;
  }
}
@media only screen and (max-width: 990px) {
  nav.navbar.bootsnav ul.nav > li.dropdown > a.dropdown-toggle:after,
  nav.navbar.bootsnav ul.nav > li.dropdown.on > a.dropdown-toggle:after {
    content: " ";
  }
  .dropdown-menu.multi-dropdown {
    left: 0 !important;
  }
  nav.navbar.bootsnav ul.nav > li:hover {
    background: transparent;
  }
  nav.navbar.bootsnav ul.nav > li > a {
    margin: 0;
  }
}
